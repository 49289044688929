import logo from './assets/images/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css'

function App() {
  return (
    <>
      <section className="top pb-4">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <img className="logo img-fluid img-responsive mt-4" src={logo} alt="GovRateOptions"/>
            </div>
            <div className="col-6">
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="mt-3">Lower Your Mortgage Rate Today</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="content pt-4 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12"/>
          </div>

          <div className="form-group row">
            <div className="col-md-3"/>
            <div className="col-md-3 col-sm-6 col-6 input-holder">
              <input type="text" className="form-control" name="fname" id="fname" placeholder="First Name"
                     autoComplete="off"
                     data-parsley-group="name" data-parsley-required-message="Enter your first name" required/>
            </div>
            <div className="col-md-3 col-sm-6 col-6 input-holder">
              <input type="text" className="form-control" name="lname" id="lname" placeholder="Last Name"
                     autoComplete="off"
                     data-parsley-group="name" data-parsley-required-message="Enter your last name" required/>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-3"/>
            <div className="col-md-4 col-sm-8 col-8 input-holder">
              <input type="text" className="form-control" name="address" id="address" placeholder="Mailing Address"
                     autoComplete="off"
                     data-parsley-group="name" data-parsley-required-message="Enter your mailing adress" required/>
            </div>
            <div className="col-md-2 col-sm-4 col-4 input-holder">
              <input type="text" className="form-control" name="zipcode" id="zipcode" placeholder="Zipcode"
                     autoComplete="off"
                     data-parsley-group="name" data-parsley-required-message="Enter your zipcode" required/>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-3"/>
            <div className="col-md-3 col-sm-3 col-xs-3 input-holder">
              <input type="email" className="form-control" name="email" id="email" placeholder="Email Address"
                     autoComplete="off"
                     data-parsley-group="name" data-parsley-required-message="Enter your email address" required/>
            </div>
            <div className="col-md-3 col-sm-3 col-xs-3 input-holder">
              <input type="text" className="form-control" name="phone" id="phone" placeholder="Phone" autoComplete="off"
                     data-parsley-group="name" data-parsley-required-message="Enter your phone number" required/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"/>
            <div className="col-md-6 text-center mt-1 mb-1">
              <div className="mb-3 disclaimer"><small>By clicking the button below, you acknowledge, consent, and agree
                to our terms at the bottom of this page. </small></div>
              <button type="button" id="btnsubmit-name" className="btn-block btn btn-primary btn-lg btn-text-white">VIEW
                MY OFFERS NOW!
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"/>
            <div className="col-md-6 mt-3 mb-3 disclaimer">
              <small>By clicking "View My Savings" you electronically agree to share your information with up to 6
                partners (possibly including Rocket Mortgage) and provide your prior "written" consent for them to
                contact you (including through automated means; autodialing, text and pre-recorded messaging) via
                telephone, mobile device (including SMS and MMS) and/or email, even if your telephone number is
                currently listed on any state, federal or corporate Do Not Call list and you agree to our Terms of Use
                and Privacy Policy. You agree that we can share your personal data with third parties, such as our
                mortgage partners, service providers and other affiliates, and that we can use this data for marketing
                and analytics, and to make your experience easier. This consent is not a condition of a
                purchase.</small>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
